import { jsx as _jsx } from "react/jsx-runtime";
import { Suspense } from "react";
import * as Sentry from "@sentry/react";
import { httpClientIntegration } from "@sentry/integrations";
import { useTranslation } from "react-i18next";
import { createRoot } from "react-dom/client";
import "./index.css";
import "./locale/i18n";
import { detectStrategy, loadPlatformBootstrapProps, } from "./mounting/definitions";
import detectCurrentPlatform from "./mounting/detectCurrentPlatform";
import console from "./log";
import { definitions } from "./mounting/definitions";
import { getShopifyShopName, getWcShopName, unmountAllFlows } from "./helpers";
import { setCheckIdInLocalStorage, setCheckVerificationStatusInLocalStorage, } from "./mounting/precheckout";
Sentry.init({
    dsn: "https://78f7f6c935294bf790e4228d78c35ba3@o249565.ingest.sentry.io/6041382",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        httpClientIntegration({
            failedRequestTargets: [
                "https://real-id.getverdict.com",
                "https://idv.link",
            ],
        }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 0.05,
    // Capture Replay for 10% of all sessions,
    // plus for 25% of sessions with an error
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 0.25,
    allowUrls: ["gitpod.io", "ngrok.io", "getverdict.com", "idv.link"],
});
async function setupEnvironment() {
    const platform = detectCurrentPlatform();
    console.debug(`Real ID :: detected platform :: ${platform}`);
    console.debug(`Real ID :: environment :: ${process.env.NODE_ENV}`);
    const platformBootstrapProps = await loadPlatformBootstrapProps(platform);
    console.debug({ platformBootstrapProps });
    const strategy = detectStrategy(platform, platformBootstrapProps, initialize);
    if (!strategy) {
        new Error("Real ID :: No matching strategy found");
    }
    const { entrySelectors, onIdCheckError, onIdCheckSuccess, onIdCheckFail, onIdCheckCreate, bootstrap, } = strategy;
    const props = await bootstrap();
    const mountEls = strategy.createMount({ entrySelectors, props, initialize });
    const additionalProps = await strategy.getAdditionalEnvironmentProps();
    const environment = {
        strategy: strategy.strategy,
        platform,
        onIdCheckError,
        onIdCheckSuccess,
        onIdCheckFail,
        onIdCheckCreate,
        // used only for automated ID checks with Vouched
        //  if the ID check is manual, then the result is always "in_review" because the merchant needs to manually review it
        onIdCheckComplete: ({ env, precheckoutDone, setCheck, check, staleCheck, done, }) => {
            const newCheck = {
                ...staleCheck,
                ...{ step: check?.step },
                ...{ job: check?.job },
            };
            setCheck(newCheck);
            console.debug(`Real ID :: onIdCheckComplete`);
            // user has failed their ID check
            if (check?.step === "in_review" || !check?.job?.result?.success) {
                env.onIdCheckError?.("results_in_review");
                const failedCheckEvent = new CustomEvent("real-id-check-failed", {
                    detail: {
                        check: newCheck,
                    },
                });
                try {
                    console.debug("Real ID :: emitting real-id-check-failed event");
                    window?.dispatchEvent(failedCheckEvent);
                }
                catch (e) {
                    console.debug("Real ID :: failed to emit real-id-check-failed event");
                    console.error(e);
                }
                done();
                return;
            }
            // user has successfully completed an ID check
            if (check?.step === "completed" && check?.job?.result?.success) {
                console.debug(`Real ID :: check ${check?.id} :: completed successfully`);
                env.onIdCheckSuccess(check);
                if (precheckoutDone?.setDone) {
                    precheckoutDone.setDone(true);
                }
                const passedCheckEvent = new CustomEvent("real-id-check-passed", {
                    detail: {
                        check: newCheck,
                    },
                });
                try {
                    console.debug("Real ID :: emitting real-id-check-passed event");
                    window?.dispatchEvent(passedCheckEvent);
                }
                catch (e) {
                    console.debug("Real ID :: failed to emit real-id-passed-check event");
                    console.error(e);
                }
                done();
            }
            // either way, pass, fail or in_review - move to the "done" step to show the final screen
            console.debug(`Real ID :: onCheckComplete :: updating check to`, {
                ...staleCheck,
                ...{ job: check?.job },
            });
        },
        ...(additionalProps || {}),
        mountEls,
    };
    console.debug(`Real ID :: initialized environment`);
    console.debug(environment);
    return { environment, mountEls, strategy };
}
function ErrorFallback() {
    const { t } = useTranslation();
    return (_jsx("div", { role: "alert", children: _jsx("p", { children: t("components.App.error_fallback") }) }));
}
const fallback = _jsx(ErrorFallback, {});
function mountFlow({ environment, mountEls, strategy, mode = "full", }) {
    console.debug(`Real ID :: initialized environment`);
    console.table(environment);
    Sentry.setTag("platform", environment?.platform);
    Sentry.setTag("strategy", environment?.strategy);
    if (mountEls) {
        const roots = mountEls.map((mountEl) => {
            console.debug(`Mounting Real ID to `, mountEl);
            const AppComponent = strategy.rootComponent(mode);
            const root = createRoot(mountEl);
            root.render(_jsx(Suspense, { fallback: _jsx("div", {}), children: _jsx(Sentry.ErrorBoundary, { fallback: fallback, showDialog: true, children: _jsx(AppComponent, { environment: environment }) }) }));
            return root;
        });
        // apply the real-id-mounted=true attribute to all entry selectors for the strategy
        // we're doing this after the mounts have been created, so the "real-id-mounted" state is as true as possible
        const entryEls = document.querySelectorAll(strategy.entrySelectors.join(", "));
        [...entryEls].map((el) => {
            el.setAttribute("real-id-mounted", "true");
        });
        window.realIdManager.roots = roots;
    }
}
function initialize() {
    if (!window.realIdManager) {
        window.realIdManager = { roots: [] };
    }
    setupEnvironment()
        .then(({ environment, mountEls, strategy, }) => {
        mountFlow({ environment, mountEls, strategy });
    })
        .catch((e) => console.debug(e));
}
initialize();
window.addEventListener("real-id-initialize", () => {
    console.log("real-id-intialize received");
    initialize();
});
window.addEventListener("updated.ecomify.cart", () => {
    console.log("updated.ecomify.cart received");
    initialize();
});
const eventEmitEls = document.querySelectorAll('.real-id-initialize, .js-go-cart-trigger, .js-go-cart-add-to-cart, [data-btn-addToCart], [data-btn-addtocart], [data-cart-sidebar], [data-add-to-cart-text], [data-action="increment"], .shopify-installments, [data-open-quick-view-popup], .quickview-icon, .modem-main');
if (eventEmitEls && eventEmitEls.length > 0) {
    eventEmitEls.forEach((eventEmitEl) => {
        console.debug(`Real ID :: registering initialize event emitter`);
        eventEmitEl.addEventListener("click", () => {
            console.debug("Real ID :: emit real-id-intialize event");
            const btnClick = new CustomEvent("real-id-initialize");
            window.dispatchEvent(btnClick);
        });
    });
}
/**
 * WooCommerce Checkout Blocks integration
 *
 * WooCommerce will emit @wordpress/hook events on specific checkout events, like when the form is loaded.
 * If the normal initization beats the client side render, then listen for the specific checkout render finished event
 *
 * List of WC events:
 * https://github.com/woocommerce/woocommerce/blob/trunk/plugins/woocommerce-blocks/docs/internal-developers/blocks/feature-flags-and-experimental-interfaces.md#usages-of-experimental-prefix
 *
 * @wordpress/hooks docs:
 * https://developer.wordpress.org/block-editor/reference-guides/packages/packages-hooks/
 */
if (window?.wp?.hooks) {
    console.debug("WC :: block usage detected");
    const hooks = window.wp.hooks;
    hooks.addAction("experimental__woocommerce_blocks-checkout-render-checkout-form", "realidcheckoutexperimental", () => {
        console.debug("WC :: checkout form rendered");
        // re-initialize flow, now that the form is rendered
        setTimeout(() => {
            initialize();
        }, 100);
    });
    hooks.addAction("woocommerce_blocks-checkout-render-checkout-form", "realidcheckout", () => {
        console.debug("WC :: checkout form rendered");
        // re-initialize flow, now that the form is rendered
        setTimeout(() => {
            initialize();
        }, 100);
    });
}
if (window?.jQuery) {
    console.log("looking for ninjaforms");
    window.jQuery(document).on("nfFormReady", () => {
        console.log("Ninja forms loaded");
        initialize();
    });
}
export function createFlow(options) {
    console.debug("Creating flow manually");
    const strategy = definitions.hosted.filter((strat) => strat.strategy === "sdk")[0];
    if (!options.target) {
        throw new Error("A target is required to mount the ID verification flow to.");
    }
    if (options.mode && !["full", "modal"].includes(options.mode)) {
        throw new Error("Only full or modal modes supported");
    }
    const environment = {
        strategy: strategy.strategy,
        platform: detectCurrentPlatform(),
        onIdCheckError: (error) => {
            // enable WC checkout depending on the error type
            if ((error === "check_already_completed" ||
                error === "verified_customer") &&
                options?.mode === "modal") {
                if (window?.realIdManager?.roots?.length > 0) {
                    [...window.realIdManager.roots].forEach((root) => {
                        console.log(`unmounting root: `, root);
                        root.unmount();
                    });
                    console.log("unmounting all mounts");
                }
            }
        },
        onIdCheckCreate: (check) => {
            setCheckIdInLocalStorage(check.id);
        },
        onIdCheckFail: () => { },
        onIdCheckSuccess: (check) => {
            // store IDv success in localstorage
            setCheckVerificationStatusInLocalStorage(check.id, true);
            // seems redundant, but also update the checkId in local storage
            // this is because multiple instances of the flow can be on one page. For instance, if the page has two mountable elements ("Buy it now" and side cart) then two instances of the flow start, and there are two separate ID checks made
            // let the completed check "win"
            setCheckIdInLocalStorage(check.id);
            if (options.mode == "modal") {
                unmountAllFlows();
            }
        },
        // used only for automated ID checks with Vouched
        //  if the ID check is manual, then the result is always "in_review" because the merchant needs to manually review it
        onIdCheckComplete: ({ env, precheckoutDone, setCheck, check, staleCheck, done, }) => {
            const newCheck = {
                ...staleCheck,
                ...{ step: check?.step },
                ...{ job: check?.job },
            };
            setCheck(newCheck);
            console.debug(`Real ID :: onIdCheckComplete`);
            // user has failed their ID check
            if (check?.step === "in_review" || !check?.job?.result?.success) {
                env.onIdCheckError?.("results_in_review");
                const failedCheckEvent = new CustomEvent("real-id-check-failed", {
                    detail: {
                        check: newCheck,
                    },
                });
                try {
                    console.debug("Real ID :: emitting real-id-check-failed event");
                    window?.dispatchEvent(failedCheckEvent);
                }
                catch (e) {
                    console.debug("Real ID :: failed to emit real-id-check-failed event");
                    console.error(e);
                }
                done();
                return;
            }
            // user has successfully completed an ID check
            if (check?.step === "completed" && check?.job?.result?.success) {
                console.debug(`Real ID :: check ${check?.id} :: completed successfully`);
                env.onIdCheckSuccess(check);
                if (precheckoutDone?.setDone) {
                    precheckoutDone.setDone(true);
                }
                const passedCheckEvent = new CustomEvent("real-id-check-passed", {
                    detail: {
                        check: newCheck,
                    },
                });
                try {
                    console.debug("Real ID :: emitting real-id-check-passed event");
                    window?.dispatchEvent(passedCheckEvent);
                }
                catch (e) {
                    console.debug("Real ID :: failed to emit real-id-passed-check event");
                    console.error(e);
                }
                done();
            }
            // either way, pass, fail or in_review - move to the "done" step to show the final screen
            console.debug(`Real ID :: onCheckComplete :: updating check to`, {
                ...staleCheck,
                ...{ job: check?.job },
            });
        },
        // infer the shopName from the window based on the platform, or simply accept it as an option (for now until public keys are a thing)
        shopName: getShopifyShopName() || options.shopName || getWcShopName(),
        shopSettings: {},
        mode: options.mode || "full",
        theme: options?.theme || {},
    };
    const entrySelectors = options?.target
        ? [options.target]
        : strategy.entrySelectors;
    // hide the entry selectors until verified
    entrySelectors.forEach((selector) => {
        const entry = document.querySelector(selector);
        if (entry) {
            entry.classList.add("hidden-until-verified");
        }
    });
    console.debug({ entrySelectors });
    const mountEls = strategy.createMount({
        entrySelectors,
        initialize,
        props: options,
    });
    console.log({ mountEls });
    if (!mountEls) {
        console.error(`No mountable elements found with ${options.mountEl}`);
    }
    mountFlow({ environment, mountEls, strategy, mode: options.mode });
}
/**
 * Public wrapper for unmounting all flows
 * @returns
 */
export function unmount() {
    return unmountAllFlows();
}
// Shopify T&N (terms and conditions app) suppport for budtender.ie
// Check if the current domain is budtender.ie
if (window.location.hostname === "budtender.ie") {
    console.log("Domain check passed: Running on budtender.ie");
    // Select the cart__ctas div
    const cartCtasDiv = document.querySelector(".cart__ctas");
    if (cartCtasDiv) {
        console.log("cart__ctas div found, setting up MutationObserver");
        // Function to handle DOM changes
        function handleDomChanges(mutationsList) {
            mutationsList.forEach((mutation) => {
                if (mutation.type === "childList") {
                    console.log("Detected childList mutation");
                    // Loop through added nodes to find the cloned button
                    mutation.addedNodes.forEach((node) => {
                        console.log("Added node detected:", node);
                        if (node instanceof HTMLButtonElement &&
                            node.getAttribute("tac-co-cloned") === "true") {
                            console.log('Cloned button found with tac-co-cloned="true"');
                            // Hide the cloned button
                            node.style.display = "none";
                            console.log("Cloned button hidden");
                            // Find the sibling div
                            const siblingDiv = node.nextElementSibling;
                            if (!siblingDiv) {
                                console.log("Sibiling div not found, exiting");
                                return;
                            }
                            const style = window.getComputedStyle(siblingDiv);
                            if (style.display === "none" &&
                                style.width === "0px" &&
                                style.height === "0px") {
                                console.log("Sibling div found:", siblingDiv);
                                siblingDiv.children;
                                // Check sibling div for hidden children
                                Array.from(siblingDiv.children).forEach((child) => {
                                    const childElement = child;
                                    console.log("Hidden child detected:", childElement);
                                    // Move the hidden child to the cart__ctas div as a sibling of the hidden button
                                    // @ts-ignore
                                    cartCtasDiv.insertBefore(childElement, node);
                                    console.log("Hidden child moved to cart__ctas div");
                                });
                            }
                            else {
                                console.log("No sibling div found next to the cloned button");
                            }
                        }
                    });
                }
            });
        }
        // Create a MutationObserver instance
        const observer = new MutationObserver(handleDomChanges);
        // Observer configuration
        const config = {
            childList: true,
            subtree: true, // Observe changes in all descendants
        };
        // Start observing the cart__ctas div
        observer.observe(cartCtasDiv, config);
        console.log("MutationObserver started on cart__ctas div");
    }
    else {
        console.log("cart__ctas div not found");
    }
}
else {
    console.log("Domain check failed: Not running on budtender.ie");
}
/**
 * Handle unverified customers on WC that manage to get to the checkout validation screen
 *
 * This will create a new Real ID Flow mount just below the error messages in the checkout page (shortcode version)
 */
if (window.jQuery) {
    console.log("Registering WC checkout error validation handler");
    window
        .jQuery(document.body)
        .on("checkout_error", function (event, errorMessage) {
        console.log("Checkout error:", errorMessage);
        // if the error message is that ID verification is required, then show the ID verification prompt
        if (errorMessage.includes("ID verification is required to checkout")) {
            // then mount the ID verification button just below the error message for visibility
            // Select the element with the class 'woocommerce-NoticeGroup-checkout'
            // const noticeGroup = document.querySelector(
            // ".woocommerce-NoticeGroup-checkout"
            // ".woocommerce-notices-wrapper"
            // );
            // Select all elements with the class '.woocommerce-notices-wrapper'
            const noticeGroups = document.querySelectorAll(".woocommerce-notices-wrapper");
            // Get the last element from the NodeList
            const noticeGroup = noticeGroups[noticeGroups.length - 1];
            if (noticeGroup) {
                // adding a half second delay just in case
                setTimeout(() => {
                    console.log("Creating WC errors mount");
                    // Create a new <div> with the class 'verify-id-prompt'
                    const newDiv = document.createElement("div");
                    newDiv.className = "verify-id-prompt";
                    newDiv.id = "wc-errors-real-id-prompt";
                    // Insert the new div as the last child within the selected element, so that way it appears immediately after the checkbox
                    noticeGroup.appendChild(newDiv);
                    console.log("Re-intializing");
                    initialize();
                }, 500);
            }
        }
    });
}
